import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
  Button,
  Autocomplete,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Skeleton,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ImageCropper from "./ImageCropper"; // Adjust the import path as necessary
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { useAppState } from "../../../appContext";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { LoadingButton } from "@mui/lab";
import {
  useAddEvent,
  useFetchBands,
  useFetchEventById,
  useUpdateEvent,
} from "../../../hooks";
import { useLocation, useNavigate } from "react-router";
import firebase from "firebase/compat/app";
import { useFetchVenueById, useFetchVenues } from "../../../hooks/venueHooks";
import { useDebounce } from "../../../utils";
import {
  convertToTimestamp,
  generateCleanWordsWithLimit,
} from "../../../utils/utility";
import PaginatedAutocomplete from "../../Elements/PaginatedAutocomplete";
import { auth } from "../../../firebase-config";

const EventForm = () => {
  const location = useLocation();
  const [enableVenueApi, setEnableVenueApi] = useState(false);
  const [enableBandApi, setEnableBandApi] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get("eventId");
  const [state, dispatch] = useAppState();

  // const eventData = state?.events.find((e) => e.id == eventId);
  const { event: eventData, loading } = useFetchEventById(eventId);
  // const isEditMode = Boolean(eventData);
  const [isEditMode, setIsEditMode] = useState(false);
  const { venue: eventVenue, loading: venueLoading } = useFetchVenueById(
    eventData?.venue
  );

  const [bandSearchTerm, setBandSearchTerm] = useState("");
  const debouncedBandTerm = useDebounce(bandSearchTerm.toLowerCase(), 300);
  const { bands: allBands, loading: allBandsLoading } = useFetchBands(
    null,
    enableBandApi
  );

  const [venueSearchTerm, setVenueSearchTerm] = useState("");
  const debouncedVenueTerm = useDebounce(venueSearchTerm.toLowerCase(), 300);
  const { venues: allVenues, loading: allVenuesLoading } = useFetchVenues(
    null,
    enableVenueApi
  );

  const [guid, setGuid] = useState(uuidv4());
  const [title, setTitle] = useState("");
  const [venue, setVenue] = useState(null);
  const [date, setDate] = useState(
    dayjs()
      .set("hour", 19)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0)
  );
  const [bands, setBands] = useState([]);
  const [eventType, setEventType] = useState("Music");
  const [facebookId, setFacebookId] = useState("");
  const [ticketUrl, setTicketUrl] = useState("");
  const [description, setDescription] = useState("");
  const [croppedImage, setCroppedImage] = useState("");
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    venue: "",
    eventType: "",
    date: "",
  });

  const { addEvent, loading: addingEvent } = useAddEvent();
  const { updateEvent, loading: updatingEvent } = useUpdateEvent();
  const navigate = useNavigate();
  const [highlight, setHighlight] = useState(false); // Add state for highlight

  useEffect(() => {
    if (eventData) {
      setIsEditMode(true);
      setGuid(eventData.id);
      setTitle(eventData.title || "");
      setDate(eventData.main_date ? dayjs(eventData.main_date) : dayjs());
      setBands(eventData.bands || []);
      setEventType(eventData.eventtype || "");
      setFacebookId(eventData.fbid || "");
      setTicketUrl(eventData.ticket_url || "");
      setDescription(eventData.eventdescription || "");
      setHighlight(eventData.highlight || false);
      // setImage(eventData.imageUrl || "");
    }
    if (eventVenue) {
      setVenue(eventVenue || null);
    }
  }, [eventData, eventVenue]);

  const validate = () => {
    let temp = {};
    temp.title = title ? "" : "This field is required.";
    temp.venue = venue ? "" : "This field is required.";
    temp.eventType = eventType ? "" : "This field is required.";
    temp.date = date.isValid() ? "" : "Invalid date.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const eventTypes = [
    "Music",
    "Trivia",
    "Open Mic",
    "Karaoke",
    "Comedy",
    "Paint",
    "Games",
    "Fundraiser",
    "DJ",
    "Others",
  ];

  const handleImageCrop = (img, croppedDataUrl) => {
    if (!img || !croppedDataUrl) {
      setImage("");
      return;
    }
    setImage(img);
    setCroppedImage(croppedDataUrl);
  };

  const handleImageUpload = async (imageFile) => {
    if (!imageFile) {
      return isEditMode ? eventData?.imageUrl || "" : "";
    }
    const storage = getStorage();
    const storageRef = ref(storage, "events/" + title.replaceAll(" ", "-"));

    try {
      const snapshot = await uploadBytes(storageRef, imageFile);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image: ", error);
      return "";
    }
  };
  function hasChanges(obj1, obj2) {
    for (const key in obj2) {
      if (obj2.hasOwnProperty(key) && obj1.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          return true; // Return true if any corresponding property value is different
        }
      }
    }
    return false; // Return false if all corresponding property values are the same
  }
  function updateObjectInArrayById(array, id, newValues) {
    return array.map((item) => {
      if (item.id === id) {
        // Merge existing item properties with newValues, giving precedence to newValues
        return { ...item, ...newValues };
      }
      return item; // Return the item unchanged if the id does not match
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setIsLoading(true);

      const imageUrl = await handleImageUpload(image);
      console.log(
        "Selected date and time:",
        date.format("YYYY-MM-DD HH:mm:ss")
      );

      const getCurrentUserId = () => {
        const user = auth.currentUser;
        return user ? user.uid : null;
      };
      const userId = getCurrentUserId();

      const formData = {
        id: guid,
        title,
        title_lowercase: title.toLowerCase(),
        venue: venue?.id,
        main_date: firebase.firestore.Timestamp.fromDate(date.toDate()),
        bandIds: bands.map((b) => b.id),
        bands,
        eventtype: eventType,
        fbid: facebookId,
        ticket_url: ticketUrl,
        eventdescription: description,
        modifieddate: firebase.firestore.Timestamp.fromDate(
          new Date(dayjs().toISOString())
        ),
        // image: croppedImage,
        imageUrl,
        city: venue?.venue_city,
        venue_name: venue?.venue_name,
        venue_state: venue?.venue_state,
        state: venue?.venue_state,
        address: venue?.address1,
        zip: venue?.VENUE_ZIP,
        venue_internallink: venue?.internallink,
        searchTerms: generateCleanWordsWithLimit(title.toLowerCase()),
        lat: parseFloat(venue?.lat),
        lon: parseFloat(venue?.lon),
        userId: userId,
        highlight,
      };
      if (isEditMode) {
        updateEvent(formData?.id, formData).then(() => {
          setGuid(uuidv4());
          setIsLoading(false);
        });
      } else {
        addEvent(formData).then(() => {
          setGuid(uuidv4());

          setIsLoading(false);
        });
      }
    }
    // Handle the submission here, such as sending data to Firebase
  };
  const navigateToDetails = (id) => {
    if (id) {
      navigate(`/events?eventId=${id}`, {
        state: { fromNavigateToDetails: true },
      });
    } else {
      navigate("/events");
    }
  };

  if (loading) {
    return (
      <Box>
        <Skeleton height={100} animation="wave" />
        <Skeleton height={100} animation="wave" />
        <Skeleton height={100} animation="wave" />
        <Skeleton height={100} animation="wave" />
        <Skeleton height={100} animation="wave" />
      </Box>
    );
  }
  return (
    <Container maxWidth={false} sx={{ mt: 12 }}>
      <Card
        raised
        sx={{ margin: "auto", maxWidth: "calc(100% - 32px)", boxShadow: 3 }}
      >
        <Box sx={{ bgcolor: "#307fc1", color: "white", p: 2 }}>
          <Typography variant="h6">Add Event</Typography>
        </Box>
        <CardContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <ImageCropper
                src={isEditMode ? eventData?.imageUrl : ""}
                onCrop={handleImageCrop}
                aspectRatio={1}
              />

              {/* <Typography variant="subtitle1" sx={{ mt: 6, color: "gray" }}>
                ID: {guid}
              </Typography> */}
              <br></br>
              <br></br>
              <Grid container spacing={3} sx={{ my: 2 }}>
                {/* Other input fields */}
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={highlight}
                        onChange={(e) => setHighlight(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Highlight this event"
                  />
                </Grid>
              </Grid>

              <TextField
                fullWidth
                label="Title of the Event"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                error={!!errors.title}
                helperText={errors.title || ""}
                sx={{ my: 2 }}
                required
              />

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <PaginatedAutocomplete
                    options={allVenues || []}
                    getOptionLabel={(option) =>
                      `${option.venue_name}, (${option.venue_city})`
                    }
                    value={venue}
                    onChange={(event, newValue) => {
                      setVenue(newValue);
                    }}
                    error={!!errors.venue}
                    helperText={errors.venue}
                    // sx={{ my: 2 }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    label={"Select Venue"}
                    onFocus={() => setEnableVenueApi(true)}
                    isLoading={allVenuesLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    sx={{ width: "100%" }}
                    label="Date & Time"
                    value={date}
                    onChange={setDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.date}
                        helperText={errors.date || ""}
                        fullWidth
                      />
                    )}
                    inputFormat="yyyy-MM-dd HH:mm:ss"
                    required
                  />
                </Grid>
              </Grid>

              <PaginatedAutocomplete
                multiple
                options={allBands || []}
                getOptionLabel={(option) =>
                  option?.alias?.length > 0
                    ? `${option.bandname} aka: ${option.alias.join(", ")}`
                    : option.bandname
                }
                required={false}
                onChange={(event, newValue) => setBands(newValue)}
                value={bands}
                label={"Select Band(s)"}
                onFocus={() => setEnableBandApi(true)}
                isLoading={allBandsLoading}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      label={option.bandname}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                sx={{ my: 2 }}
              />

              <Grid container spacing={3} sx={{ my: 2 }}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth error={!!errors.eventType}>
                    <InputLabel id="event-type-label">Event Type</InputLabel>
                    <Select
                      labelId="event-type-label"
                      id="event-type"
                      value={eventType}
                      label="Event Type"
                      onChange={(e) => setEventType(e.target.value)}
                      required
                    >
                      {eventTypes.map((type, index) => (
                        <MenuItem key={index + type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.eventType || ""}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Facebook ID Numbers only"
                    value={facebookId}
                    onChange={(e) => setFacebookId(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Ticket URL include https://"
                    value={ticketUrl}
                    onChange={(e) => setTicketUrl(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ pb: 1 }}>
                <Typography variant="body1" sx={{ pt: 1 }}>
                Facebook ID only use the event number:
                <br />
                <a
      href={`https://www.facebook.com/events/${eventData?.fbid || "543736524712699"}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "#1976d2", textDecoration: "underline" }}
    >
      https://www.facebook.com/events/<strong style={{ color: "red" }}>{eventData?.fbid || "543736524712699"}</strong>
    </a>
                </Typography>
              </Grid>

              <TextField
                fullWidth
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={3}
                sx={{ my: 2 }}
              />

              <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    navigateToDetails(null);
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={addingEvent || updatingEvent || isLoading}
                >
                  Submit
                </LoadingButton>
              </Box>
            </form>
          </LocalizationProvider>
        </CardContent>
      </Card>
    </Container>
  );
};

export default EventForm;
