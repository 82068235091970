import { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  getDoc,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../firebase-config";
import { useAppState } from "../appContext";
import { useNotification } from "../components/Notification";
import { query, where, orderBy, limit, startAfter } from "firebase/firestore";
import { GeoFirestore } from "geofirestore";
const geoFirestore = new GeoFirestore(db);

// Custom hook to add a new page to Firestore
export const useAddPage = () => {
  const [loading, setLoading] = useState(false);
  const { showSuccessMessage, showWarningMessage } = useNotification();

  const addPage = async (pageData) => {
    try {
      setLoading(true);
      await setDoc(doc(db, "pages", `${pageData.id}`), pageData);

      // Get the event collection reference
      const pageCollection = geoFirestore.collection("pages");

      // Add the event data with geolocation using GeoFirestore's set method
      await pageCollection.doc(`${pageData.id}`).set({
        ...pageData,
      });

      showSuccessMessage("Page added successfully");
    } catch (error) {
      showWarningMessage("Error adding page:", error);
    } finally {
      setLoading(false);
    }
  };
  return { addPage, loading };
};

// Custom hook to update an existing page in Firestore
export const useUpdatePage = () => {
  const [loading, setLoading] = useState(false);
  const { showSuccessMessage, showWarningMessage } = useNotification();

  const updatePage = async (pageId, pageData) => {
    try {
      setLoading(true);
      const pageRef = doc(db, "pages", pageId + "");
      await updateDoc(pageRef, pageData);
      showSuccessMessage("Page updated successfully");
    } catch (error) {
      console.log("Page updated unsuccessfully", error, pageId);
      showWarningMessage("Error updating page:", error);
    } finally {
      setLoading(false);
    }
  };

  return { updatePage, loading };
};

// Custom hook to delete an page from Firestore
export const useDeletePage = () => {
  const [loading, setLoading] = useState(false);
  const { showSuccessMessage, showWarningMessage } = useNotification();
  const [state, dispatch] = useAppState();

  const deletePage = async (pageId) => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, "pages", pageId));

      showSuccessMessage("Page Deleted successfully");
    } catch (error) {
      showWarningMessage("Error Deleting Page:", error);
    } finally {
      setLoading(false);
    }
  };

  return { deletePage, loading };
};
export const useFetchPageByInternalLink = () => {
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPage = async (internallink) => {
    if (!internallink) {
      setError("Internal link is required");
      return;
    }

    try {
      setLoading(true);
      const pagesRef = collection(db, "pages");
      const q = query(
        pagesRef,
        where("internallink", "==", `/${internallink.toLowerCase()}/`)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const pageDoc = querySnapshot.docs[0]; // Assuming internallink is unique
        setPage({
          id: pageDoc.id,
          ...pageDoc.data(),
        });
        return { id: pageDoc.id, ...pageDoc.data() };
      } else {
        setError("Page not found");
      }
    } catch (err) {
      setError(`Error fetching page: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return { page, loading, error, fetchPage };
};
export const useFetchPages = (enableApi = false) => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPages = async () => {
      setLoading(true);

      try {
        // Fetch new data from the database
        let pagesQuery = collection(db, "pages");

        const querySnapshot = await getDocs(pagesQuery);
        const fetchedPages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPages(fetchedPages);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching pages:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    if (enableApi) fetchPages();
  }, [enableApi]);

  return { pages, loading, error };
};
export const useFetchPageById = (pageId) => {
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPage = async () => {
      try {
        if (!pageId) return;
        setLoading(true);
        const pageDocRef = doc(db, "pages", pageId);
        const pageDoc = await getDoc(pageDocRef);

        if (pageDoc.exists()) {
          setPage({
            id: pageDoc.id,
            ...pageDoc.data(),
          });
        } else {
          setError("Page not found");
        }
      } catch (err) {
        console.log(`Error fetching page: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPage();
  }, [pageId]);

  return { page, loading, error };
};
