import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  Box,
  Autocomplete,
  Skeleton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { useAppState } from "../../../appContext";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router";
import {
  useAddVenue,
  useFetchVenueById,
  useFetchVenueByInternalLink,
  useUpdateVenue,
} from "../../../hooks/venueHooks";
import ImageCropper from "../Events/ImageCropper";
import SocialMediaLinkAdder from "../../SocialMediaLinkAdder";
import { GeoPoint } from "firebase/firestore";
import PaginatedAutocomplete from "../../Elements/PaginatedAutocomplete";
import { generateCleanWordsWithLimit } from "../../../utils/utility";
import { auth } from "../../../firebase-config";

const VenueForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const venueId = searchParams.get("venueId");
  const [state, dispatch] = useAppState();

  const { venue: venueData, loading: venueDataLoading } =
    useFetchVenueById(venueId);
  const [isEditMode, setIsEditMode] = useState(false);

  const [guid, setGuid] = useState(uuidv4());
  const [venueName, setVenueName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [venueUrl, setVenueUrl] = useState("");
  const [zone, setZone] = useState("America/New_York");
  const [venueState, setVenueState] = useState("");
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState(null);
  const [internalLink, setInternalLink] = useState("");

  const { addVenue, loading: addingVenue } = useAddVenue();
  const { updateVenue, loading: updatingVenue } = useUpdateVenue();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchVenue, loading: venueLoading } = useFetchVenueByInternalLink();

  const [errors, setErrors] = useState({
    venueName: "",
    city: "",
    zone: "",
    address: "",
    latitude: "",
    longitude: "",
    zip: "",
    venueState: "",
    internalLink: "",
  });
  const removeSlashes = (str) => {
    if (!str) return null;
    if (str.startsWith("/")) {
      str = str.slice(1);
    }
    if (str.endsWith("/")) {
      str = str.slice(0, -1);
    }
    return str;
  };

  useEffect(() => {
    if (venueData) {
      setIsEditMode(true);
      setGuid(venueData.id);
      setVenueName(venueData.venue_name);
      setAddress(venueData.address1);
      setCity(venueData.venue_city);
      setZip(venueData.VENUE_ZIP);
      setVenueUrl(venueData.venue_url);
      setZone(venueData.tzone);
      setVenueState(venueData.venue_state);
      setSocialMediaLinks(venueData.socialMediaLinks || []);
      setLongitude(venueData.lon);
      setLatitude(venueData.lat);
      setDescription(venueData.description || "");
      setImageUrl(venueData.imageUrl || "");
      setInternalLink(removeSlashes(venueData.internallink) || "");
    }
  }, [venueData]);

  const validate = () => {
    let temp = {};
    temp.venueName = venueName ? "" : "This field is required.";
    temp.city = city ? "" : "This field is required.";
    temp.address = address ? "" : "This field is required.";
    temp.latitude = latitude ? "" : "This field is required.";
    temp.longitude = longitude ? "" : "This field is required.";
    temp.zip = zip ? "" : "This field is required.";
    temp.venueState = venueState ? "" : "This field is required.";
    temp.internalLink = internalLink
      ? /^[a-zA-Z0-9-]+$/.test(internalLink)
        ? ""
        : "Only alphanumeric characters and hyphens are allowed."
      : "This field is required.";
    setErrors(temp);

    return Object.values(temp).every((x) => x === "");
  };

  const handleImageCrop = (img, croppedDataUrl) => {
    if (!img || !croppedDataUrl) {
      setImage("");
      return;
    }
    setImage(img);
    setImageUrl(croppedDataUrl);
  };

  const handleImageUpload = async (imageFile) => {
    if (!imageFile) {
      return imageUrl || "";
    }
    const storage = getStorage();
    const storageRef = ref(storage, "venues/" + venueName.replaceAll(" ", "-"));

    try {
      const snapshot = await uploadBytes(storageRef, imageFile);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image: ", error);
      return "";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setIsLoading(true);

      const finalImageUrl = image ? await handleImageUpload(image) : imageUrl;

      const getCurrentUserId = () => {
        const user = auth.currentUser;
        return user ? user.uid : null;
      };
      const userId = getCurrentUserId();

      const formData = {
        id: guid,
        venue_name: venueName,
        venue_city: city,
        venue_url: venueUrl,
        description,
        lon: longitude,
        lat: latitude,
        modifieddate: dayjs().toISOString(),
        imageUrl: finalImageUrl,
        tzone: zone,
        VENUE_ZIP: zip,
        address1: address,
        venue_state: venueState,
        socialMediaLinks,
        coordinates: new GeoPoint(parseFloat(latitude), parseFloat(longitude)),
        searchTerms: generateCleanWordsWithLimit(venueName.toLowerCase()),
        title_lowercase: venueName.toLowerCase(),
        internallink: `/${internalLink.toLowerCase()}/`,
        userId: userId,
      };
      fetchVenue(internalLink).then(async (res) => {
        if (isEditMode) {
          if (res?.id && res?.id !== venueData.id) {
            let err = {
              ...errors,
              internalLink: `${internalLink} already exists`,
            };
            setErrors(err);
          } else {
            await updateVenue(guid, formData);
            navigateToDetails(guid);
          }
        } else {
          if (res?.id) {
            let err = {
              ...errors,
              internalLink: `${internalLink} already exists`,
            };
            setErrors(err);
          } else {
            await addVenue({ ...formData, createdAt: dayjs().toISOString() });

            navigateToDetails(guid);
          }
        }
      });

      setIsLoading(false);
    }
  };

  const navigateToDetails = (id) => {
    if (id) {
      navigate(`/venues?venueId=${id}`, {
        state: { fromNavigateToDetails: true },
      });
    } else {
      navigate("/venues");
    }
  };
  if (venueDataLoading) {
    return (
      <>
        <Box width={"100%"}>
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
        </Box>
      </>
    );
  }

  return (
    <Container maxWidth={false} sx={{ mt: 12 }}>
      <Card
        raised
        sx={{ margin: "auto", maxWidth: "calc(100% - 32px)", boxShadow: 3 }}
      >
        <Box sx={{ bgcolor: "#307fc1", color: "white", p: 2 }}>
          <Typography variant="h6">
            {isEditMode ? "Edit Venue" : "Add Venue"}
          </Typography>
        </Box>
        <CardContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <ImageCropper
                src={isEditMode ? imageUrl : ""}
                onCrop={handleImageCrop}
                aspectRatio={1}
              />

              {/* <Typography variant="subtitle1" sx={{ mt: 6, color: "gray" }}>
                ID: {guid}
              </Typography> */}
<br /><br />
              <TextField
                fullWidth
                label="Venue Name"
                value={venueName}
                onChange={(e) => setVenueName(e.target.value)}
                error={!!errors.venueName}
                helperText={errors.venueName || ""}
                sx={{ my: 2 }}
                required
              />

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    error={!!errors.address}
                    helperText={errors.address || ""}
                    sx={{ my: 2 }}
                    required
                  />
                </Grid>
                <Grid sx={{ my: 2 }} item xs={12} sm={2}>
                  <PaginatedAutocomplete
                    options={state.cities || []}
                    label="City"
                    value={city}
                    onChange={(event, newValue) => setCity(newValue)}
                    error={!!errors.city}
                    helperText={errors.city || ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Autocomplete
                    fullWidth
                    options={state.states || []}
                    getOptionLabel={(option) => option}
                    value={venueState}
                    onChange={(event, newValue) => setVenueState(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State"
                        error={!!errors.venueState}
                        helperText={errors.venueState || ""}
                        sx={{ my: 2 }}
                        required
                        autoComplete="off"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} sx={{ display: 'none' }}>
                  <TextField
                    fullWidth
                    label="Zone"
                    disabled
                    value={zone}
                    sx={{ my: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Zip Code"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    error={!!errors.zip}
                    helperText={errors.zip || ""}
                    sx={{ my: 2 }}
                    required
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Latitude (40.728...)"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    error={!!errors.latitude}
                    helperText={errors.latitude || ""}
                    sx={{ my: 2 }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Longitude (-73.999...)"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    error={!!errors.longitude}
                    helperText={errors.longitude || ""}
                    sx={{ my: 2 }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Venue URL"
                    value={venueUrl}
                    onChange={(e) => setVenueUrl(e.target.value)}
                    sx={{ my: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} >
                  <TextField
                    fullWidth
                    label="venue directory name"
                    value={internalLink}
                    onChange={(e) => setInternalLink(e.target.value)}
                    error={!!errors.internalLink}
                    helperText={errors.internalLink || ""}
                    required
                    sx={{ my: 2 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ pb: 1 }}>
                  <Typography variant="body1" sx={{ pt: 1 }}>
                    New: To get latitude and longitude: Open the <a href="https://www.google.com/maps/search/" target="_blank" rel="noopener noreferrer">Google Maps</a> and right-click on the pin and select the coordinates.<br></br>
                    Venue Directory name will the url. If you type <strong>bitterend</strong>, url will be https://www.webtunes.com/venues/<strong>bitterend</strong>
                  </Typography>
                </Grid>
              <SocialMediaLinkAdder
                initialLinks={socialMediaLinks}
                onLinksChange={(v) => setSocialMediaLinks(v)}
              />
              <TextField
                fullWidth
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
                sx={{ my: 2 }}
              />
              <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigateToDetails(null)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={addingVenue || updatingVenue || isLoading}
                >
                  Submit
                </LoadingButton>
              </Box>
            </form>
          </LocalizationProvider>
        </CardContent>
      </Card>
    </Container>
  );
};

export default VenueForm;
