import { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  getDoc,
  getCountFromServer,
  or,
  and,
} from "firebase/firestore";
import { db } from "../firebase-config";
import { useAppState } from "../appContext";
import { useNotification } from "../components/Notification";
import { query, where, orderBy, limit, startAfter } from "firebase/firestore";
import { GeoFirestore } from "geofirestore";
const geoFirestore = new GeoFirestore(db);

const venuesCache = {};

const generateVenuesCacheKey = (searchTerms, skip, type, take) => {
  const searchKey = searchTerms?.join("-") || "all";
  return `${searchKey}-${skip}-${type}-${take}`;
};

export const useFetchVenuesWithSearch = (
  take = 20,
  skip = 0,
  searchTerms = [],
  type = "accordion"
) => {
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [lastDoc, setLastDoc] = useState(null); // Track the last document for pagination
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVenues = async () => {
      setLoading(true);

      try {
        const cacheKey = generateVenuesCacheKey(searchTerms, skip, type, take);
        // Check if results for this query are already cached
        if (venuesCache[cacheKey]) {
          const cachedResult = venuesCache[cacheKey];
          setVenues(cachedResult.venues);
          setTotalItems(cachedResult.totalItems);
          setLastDoc(cachedResult.lastDoc);
          setLoading(false);
          return;
        }

        let venuesQuery = collection(db, "venues");

        if (searchTerms.length > 0) {
          venuesQuery = query(
            venuesQuery,
              where("searchTerms", "array-contains-any", searchTerms)
            )
        }
        const countSnapshot = await getCountFromServer(venuesQuery);

        const totalCount = countSnapshot.data().count;

        venuesQuery = query(
          venuesQuery,
          orderBy("title_lowercase", "asc"),
          limit(take)
        );

        if (skip > 0 && lastDoc) {
          venuesQuery = query(venuesQuery, startAfter(lastDoc));
        }

        const querySnapshot = await getDocs(venuesQuery);
        const fetchedVenues = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

        // Get total count of items
        // const totalCountSnapshot = await getDocs(query(venuesQuery));
        // const totalCount = totalCountSnapshot.size;

        // Cache the result
        venuesCache[cacheKey] = {
          venues: fetchedVenues,
          totalItems: totalCount,
          lastDoc: lastVisible,
        };

        setVenues(fetchedVenues);
        setTotalItems(totalCount);
        setLastDoc(lastVisible);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching venues:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchVenues();
  }, [take, skip, searchTerms, type]);

  return { venues, loading, totalItems, error };
};

let lastCachedVenues = null; // Store the last cached data globally

export const useFetchVenues = (refresh = null, enableApi = true) => {
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVenues = async () => {
      const cacheKey = generateVenuesCacheKey([], 0, 0, refresh);

      // If refresh is null and enableApi is false, return the last cached data.
      if (refresh === null && !enableApi) {
        if (lastCachedVenues) {
          setVenues(lastCachedVenues);
          setLoading(false);
        } else {
          setLoading(false); // No data to show, but stop loading.
        }
        return;
      }

      // If API is enabled or refresh is dynamic, fetch new data.
      setLoading(true);

      // If cached data exists for this key, use the cached version.
      if (venuesCache[cacheKey]) {
        const cachedResult = venuesCache[cacheKey];
        setVenues(cachedResult.venues);
        setLoading(false);
        return;
      }

      try {
        // Fetch new data from the database
        let venuesQuery = collection(db, "venues");
        venuesQuery = query(venuesQuery, orderBy("title_lowercase", "asc"));

        const querySnapshot = await getDocs(venuesQuery);
        const fetchedVenues = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Cache the fetched venues using the generated cache key
        venuesCache[cacheKey] = {
          venues: fetchedVenues,
        };

        // Also update the last cached data
        lastCachedVenues = fetchedVenues;

        setVenues(fetchedVenues);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching venues:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    // Fetch new data only if enableApi is true or refresh is dynamic.
    fetchVenues();
  }, [refresh, enableApi]);

  return { venues, loading, error };
};

export const useFetchVenueById = (venueId) => {
  const [venue, setVenue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVenue = async () => {
      try {
        if (!venueId) return;
        setLoading(true);
        const venueDocRef = doc(db, "venues", venueId);
        const venueDoc = await getDoc(venueDocRef);

        if (venueDoc.exists()) {
          setVenue({
            id: venueDoc.id,
            ...venueDoc.data(),
          });
        } else {
          setError("Venue not found");
        }
      } catch (err) {
        console.log(`Error fetching venue: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVenue();
  }, [venueId]);

  return { venue, loading, error };
};

// Custom hook to add a new venue to Firestore
export const useAddVenue = () => {
  const { showSuccessMessage, showWarningMessage } = useNotification();

  const [loading, setLoading] = useState(false);

  const addVenue = async (venueData) => {
    try {
      setLoading(true);
      await setDoc(doc(db, "venues", `${venueData.id}`), venueData);

      // Get the event collection reference
      const venueCollection = geoFirestore.collection("venues");

      // Add the event data with geolocation using GeoFirestore's set method
      await venueCollection.doc(`${venueData.id}`).set({
        ...venueData,
      });

      showSuccessMessage("Venue added successfully");
    } catch (error) {
      showWarningMessage("Error adding venue:", error);
    } finally {
      setLoading(false);
    }
  };

  return { addVenue, loading };
};

// Custom hook to update an existing venue in Firestore
export const useUpdateVenue = () => {
  const [loading, setLoading] = useState(false);
  const { showSuccessMessage, showWarningMessage } = useNotification();
  const [state, dispatch] = useAppState();

  const updateVenue = async (venueId, venueData) => {
    try {
      setLoading(true);
      const venueRef = doc(db, "venues", venueId + "");
      await updateDoc(venueRef, venueData);
      showSuccessMessage("Venue Updated successfully");
    } catch (error) {
      console.log(error, venueId);
      showWarningMessage("Failed  updating Venue:", error);
    } finally {
      setLoading(false);
    }
  };

  return { updateVenue, loading };
};
// Custom hook to delete an venue from Firestore
export const useDeleteVenue = () => {
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useAppState();

  const { showSuccessMessage, showWarningMessage } = useNotification();

  const deleteVenue = async (venueId) => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, "venues", venueId));
      dispatch({
        type: "SET_PROP",
        payload: {
          key: "venues",
          value: state?.venues.filter((e) => e.id != venueId),
        },
      });
      showSuccessMessage("Venue Deleted successfully");
    } catch (error) {
      showWarningMessage("Error Deleting Venue:", error);
    } finally {
      setLoading(false);
    }
  };

  return { deleteVenue, loading };
};
export const useFetchUtilityData = () => {
  const [utilityData, setUtilityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUtilityData = async () => {
      try {
        const utilityRef = db.collection("utility").doc("1");
        const doc = await utilityRef.get();
        if (doc.exists) {
          setUtilityData(doc.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUtilityData();
  }, []);

  return { utilityData, loading, error };
};
export const useFetchVenueByInternalLink = () => {
  const [venue, setVenue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchVenue = async (internallink) => {
    if (!internallink) {
      setError("Internal link is required");
      return;
    }

    try {
      setLoading(true);
      const venuesRef = collection(db, "venues");
      const q = query(
        venuesRef,
        where("internallink", "==", `/${internallink.toLowerCase()}/`)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const venueDoc = querySnapshot.docs[0]; // Assuming internallink is unique
        setVenue({
          id: venueDoc.id,
          ...venueDoc.data(),
        });
        return { id: venueDoc.id, ...venueDoc.data() };
      } else {
        setError("Venue not found");
      }
    } catch (err) {
      setError(`Error fetching venue: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return { venue, loading, error, fetchVenue };
};
