import React, { useState } from "react";
import { TextField, Button, Link, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase-config";
import { useUserAuth } from "../../context/UserAuthContext";
import LoadingButton from "../Elements/LoadingButton/LoadingButton";

const useStyles = makeStyles(() => ({
  link: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "#1976d2",
  },
}));

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isResetting, setIsResetting] = useState(false);
  const navigate = useNavigate();
  const { logIn } = useUserAuth();
  const classes = useStyles();

  const handleLogin = async () => {
    setError("");
    try {
      await logIn(email, password);
      navigate("/dashboard"); // Redirect on successful login
    } catch (err) {
      setError(err.message || "Failed to log in");
    }
  };

  const handleResetPassword = async () => {
    setIsResetting(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setError("");
      alert("Password reset email sent! Check your inbox.");
    } catch (err) {
      setError(err.message || "Failed to send password reset email");
    }
    setIsResetting(false);
  };

  return (
    <Grid container spacing={3} sx={{ maxWidth: 400, mx: "auto", mt: 5 }}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Login
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Typography color="error">{error}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <LoadingButton
          onClick={handleLogin}
          loading={false} // Replace with a loading state if needed
          fullWidth
          variant="contained"
          color="primary"
        >
          Log In
        </LoadingButton>
      </Grid>
      <Grid item xs={12}>
        <Link
          className={classes.link}
          onClick={handleResetPassword}
          disabled={!email || isResetting}
        >
          Forgot Password?
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          Don't have an account?{" "}
          <Link className={classes.link} onClick={() => navigate("/signup")}>
            Sign up here
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
