import { useState, useEffect, useRef } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  query,
  where,
  limit,
  startAfter,
  getCountFromServer,
  GeoPoint,
  orderBy,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase-config"; // Adjust the import according to your project structure
import { useNotification } from "../components/Notification";
import { useAppState } from "../appContext";
import { GeoFirestore } from "geofirestore"; // Ensure correct import path
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import moment from "moment";
const currentDate = new Date();
const fourHoursBefore = new Date(
  currentDate.setHours(currentDate.getHours() - 4)
);

// Custom hook to fetch all events from Firestore
const geoFirestore = new GeoFirestore(db);
const parseQueryString = (field, operator, value) => {
  switch (operator) {
    case "==":
      return where(field, "==", value);
    case "!=":
      return where(field, "!=", value);
    case "<":
      return where(field, "<", value);
    case "<=":
      return where(field, "<=", value);
    case ">":
      return where(field, ">", value);
    case ">=":
      return where(field, ">=", value);
    case "array-contains":
      return where(field, "array-contains", value);
    case "in":
      return where(field, "in", value);
    case "array-contains-any":
      return where(field, "array-contains-any", value);
    default:
      throw new Error(`Unsupported query operator: ${operator}`);
  }
};

const chunkArray = (array, size) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

const sortEvents = (events) => {
  const now = moment(); // Get the current time

  // Separate future/current events and past events
  const futureEvents = events.filter((event) =>
    moment(event.main_date).isSameOrAfter(now)
  );
  const pastEvents = events.filter((event) =>
    moment(event.main_date).isBefore(now)
  );

  // Sort future/current events in ascending order
  futureEvents.sort((a, b) => moment(a.main_date) - moment(b.main_date));

  // Sort past events in descending order
  pastEvents.sort((a, b) => moment(b.main_date) - moment(a.main_date));

  // Combine the sorted arrays
  return [...futureEvents, ...pastEvents];
};

const fetchStandardFilteredRecords = async (filters, take, lastDoc, db) => {
  let orderByField = "main_date";
  const inequalities = ["<", "<=", "!=", "not-in", ">", ">="];
  let eventQuery = null;
  eventQuery = collection(db, "events");

  const hasInEquality =
    filters.filter((f) => inequalities.includes(f.operator)).length > 0;
  const hasSearchFilter =
    filters.filter((f) => f.field === "title_lowercase").length > 0;
  if (hasSearchFilter) {
    orderByField = "title_lowercase";
  }

  for (const { field, operator, value } of filters) {
    eventQuery = query(eventQuery, parseQueryString(field, operator, value));
  }
  if (!hasInEquality) {
    eventQuery = query(eventQuery, where("main_date", ">=", fourHoursBefore));
  }
  const countSnapshot = await getCountFromServer(eventQuery);

  eventQuery = query(eventQuery, orderBy(orderByField, "asc"), limit(take));
  if (lastDoc) {
    eventQuery = query(eventQuery, startAfter(lastDoc));
  }

  const querySnapshot = await getDocs(eventQuery);
  const docs = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  const totalCount = countSnapshot.data().count;
  return { docs, totalCount, lastVisible, isPaginated: true };
};

export const useFetchEvents = (
  take = 20,
  skip = 0,
  filters = [],
  enableApi = false,
  refreshTrigger = 0
) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [isPaginated, setIsPaginated] = useState(true);
  const [lastDoc, setLastDoc] = useState(null); // Track the last document for pagination
  const [prevFilterString, setPrevFilterString] = useState(""); // Track previous filters
  const [refresh, setRefresh] = useState(0);
  const localEventsRef = useRef([]); // Ref to store all fetched records locally
  const isFetchingRef = useRef(false); // Ref to track if a fetch operation is in progress

  useEffect(() => {
    setLoading(true);
    if (!enableApi) {
      return;
    }
    const currentFilterString = JSON.stringify(filters);

    // Reset lastDoc and localEvents if filters have changed
    if (
      currentFilterString !== prevFilterString ||
      refresh !== refreshTrigger
    ) {
      isFetchingRef.current = false;
      setLastDoc(null);
      localEventsRef.current = []; // Clear local events in the ref
      setPrevFilterString(currentFilterString); // Update the previous filter string
    }

    // Check if the local data covers the requested range
    if (localEventsRef.current.length >= skip + take) {
      setEvents(
        sortEvents(
          localEventsRef.current.slice(skip, skip + take).map((d) => {
            const mainDate = d.main_date.toDate(); // Convert Firestore Timestamp to JavaScript Date
            return {
              ...d,
              main_date: moment(mainDate).format("YYYY-MM-DD HH:mm:ss"),
              modiefieddate: moment(d.modifieddate?.toDate()).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            };
          })
        )
      );
      setLoading(false);
      return; // No need to proceed further if local data is sufficient
    }

    // Prevent overlapping fetches using ref
    if (isFetchingRef.current) return;

    // If local data is not sufficient, fetch from Firestore
    const fetchEvents = async () => {
      setLoading(true);

      setLoading(true);
      isFetchingRef.current = true; // Mark fetching as in progress
      try {
        let result;
        // Only Standard Filters applied
        result = await fetchStandardFilteredRecords(
          filters,
          take,
          currentFilterString !== prevFilterString ? null : lastDoc,
          db
        );

        // Append new results to localEventsRef
        localEventsRef.current = [...localEventsRef.current, ...result.docs];
        // Process and set events with the necessary formatting and sorting
        setEvents(
          sortEvents(
            (result.isPaginated
              ? localEventsRef.current.slice(skip, skip + take)
              : localEventsRef.current
            ) // No slicing if not paginated
              .map((d) => {
                const mainDate = d.main_date.toDate(); // Convert Firestore Timestamp to JavaScript Date
                return {
                  ...d,
                  main_date: moment(mainDate).format("YYYY-MM-DD HH:mm:ss"),
                  modifieddate: moment(d.modifieddate?.toDate()).format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                };
              })
          )
        );

        setTotalItems(result.totalCount);
        setIsPaginated(result.isPaginated);
        setLastDoc(result.lastVisible || null);
        setRefresh(refreshTrigger);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
        isFetchingRef.current = false; // Fetching is complete
      }
    };

    fetchEvents(); // Call the fetch function if local data is insufficient
  }, [take, skip, filters, refreshTrigger]);

  return { events, loading, totalItems, isPaginated };
};

// End new fetch evemts
export const useFetchEventsByVenueId = (venue, showPastEvents) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    setLoading(true);

    const fetchEvents = async () => {
      setLoading(true);
      try {
        const eventsCollection = collection(db, "events");
        let q;
        if (showPastEvents) {
          q = query(
            eventsCollection,
            where("venue", "==", venue),
            orderBy("main_date", "asc")
          );
        } else {
          q = query(
            eventsCollection,
            where("venue", "==", venue),
            where("main_date", ">=", fourHoursBefore),
            orderBy("main_date", "asc")
          );
        }

        const querySnapshot = await getDocs(q);

        const eventsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(
          sortEvents(
            eventsList.map((d) => {
              const mainDate = d.main_date.toDate(); // Convert Firestore Timestamp to JavaScript Date
              // const formattedMainDate = formatDate(mainDate);
              return {
                ...d,
                main_date: moment(mainDate).format("YYYY-MM-DD HH:mm:ss"),
                modifieddate: moment(d.modifieddate?.toDate()).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
              };
            })
          )
        );
        setTotalItems(querySnapshot.size);
      } catch (error) {
        alert("Error in fetching");
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };
    if (venue) fetchEvents();
  }, [venue, showPastEvents]);

  return { events, loading, totalItems };
};

export const useFetchEventById = (eventId) => {
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        if (!eventId) {
          setLoading(false);

          return;
        }
        setLoading(true);
        const eventDocRef = doc(db, "events", eventId);
        const eventDoc = await getDoc(eventDocRef);

        if (eventDoc.exists()) {
          const eventData = eventDoc.data();
          const mainDate = eventData.main_date?.toDate();
          const modifiedDate = eventData.modifieddate?.toDate();
          setEvent({
            id: eventDoc.id,
            ...eventData,
            main_date: mainDate
              ? moment(mainDate).format("YYYY-MM-DD HH:mm:ss")
              : null,
            modifieddate: modifiedDate
              ? moment(modifiedDate).format("YYYY-MM-DD HH:mm:ss")
              : null,
          });
        } else {
          setError("Event not found");
        }
      } catch (err) {
        setError(`Error fetching event: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  return { event, loading, error };
};

export const useFetchEventsByBandId = (band, showPastEvents) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    setLoading(true);

    const fetchEvents = async () => {
      setLoading(true);
      try {
        const eventsCollection = collection(db, "events");
        let q;
        if (showPastEvents) {
          q = query(
            eventsCollection,
            where("bandIds", "array-contains", band),
            orderBy("main_date", "asc")
          );
        } else {
          q = query(
            eventsCollection,
            where("bandIds", "array-contains", band),
            where("main_date", ">=", fourHoursBefore),
            orderBy("main_date", "asc")
          );
        }

        const querySnapshot = await getDocs(q);

        const eventsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(
          sortEvents(
            eventsList.map((d) => {
              const mainDate = d.main_date.toDate(); // Convert Firestore Timestamp to JavaScript Date
              // const formattedMainDate = formatDate(mainDate);
              return {
                ...d,
                main_date: moment(mainDate).format("YYYY-MM-DD HH:mm:ss"),
                modifieddate: moment(d.modifieddate?.toDate()).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
              };
            })
          )
        );
        setTotalItems(querySnapshot.size);
      } catch (error) {
        alert("Error in fetching");
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };
    if (band) fetchEvents();
  }, [band, showPastEvents]);

  return { events, loading, totalItems };
};

// Custom hook to add a new event to Firestore
export const useAddEvent = () => {
  const { showSuccessMessage, showWarningMessage } = useNotification();
  const [loading, setLoading] = useState(false);

  const addEvent = async (eventData) => {
    try {
      setLoading(true);

      // Get the event collection reference
      const eventsCollection = geoFirestore.collection("events");

      // Add the event data with geolocation using GeoFirestore's set method
      await eventsCollection.doc(`${eventData.id}`).set({
        ...eventData,
        coordinates: new firebase.firestore.GeoPoint(
          parseFloat(eventData.lat) || 0.0,
          parseFloat(eventData.lon) || 0.0
        ),
      });

      showSuccessMessage("Event added successfully");
    } catch (error) {
      console.error("Error adding event:", error);
      showWarningMessage("Error adding event:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return { addEvent, loading };
};
// Custom hook to update an existing event in Firestore

export const useUpdateEvent = () => {
  const [loading, setLoading] = useState(false);
  const { showSuccessMessage, showWarningMessage } = useNotification();

  const updateEvent = async (eventId, eventData) => {
    try {
      setLoading(true);

      // Get the event collection reference from GeoFirestore
      const eventsCollection = geoFirestore.collection("events");

      // Update the event document using GeoFirestore's update method
      await eventsCollection.doc(eventId).update({
        ...eventData,
        coordinates: new firebase.firestore.GeoPoint(
          parseFloat(eventData.lat) || 0.0,
          parseFloat(eventData.lon) || 0.0
        ),
      });

      showSuccessMessage("Event updated successfully");
    } catch (error) {
      console.error("Error updating event:", error);
      showWarningMessage("Error updating event:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return { updateEvent, loading };
};

// Custom hook to delete an event from Firestore
export const useDeleteEvent = () => {
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useAppState();

  const { showSuccessMessage, showWarningMessage } = useNotification();

  const deleteEvent = async (eventId) => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, "events", `${eventId}`));
      dispatch({
        type: "SET_PROP",
        payload: {
          key: "events",
          value: state?.events?.filter((e) => e.id != eventId),
        },
      });
      showSuccessMessage("Event Deleted successfully");
    } catch (error) {
      showWarningMessage("Error Deleting event:", error);
    } finally {
      setLoading(false);
    }
  };

  return { deleteEvent, loading };
};
export const useFetchEventsByDate = (isFuture, take) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const eventsCollection = collection(db, "events");
        const q = query(
          eventsCollection,
          where("main_date", isFuture ? ">=" : "<", fourHoursBefore),
          orderBy("main_date", isFuture ? "asc" : "desc"),
          limit(take)
        );
        const querySnapshot = await getDocs(q);

        const eventsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(
          sortEvents(
            eventsList.map((d) => {
              const mainDate = d?.main_date?.toDate(); // Convert Firestore Timestamp to JavaScript Date
              // const formattedMainDate = formatDate(mainDate);
              return {
                ...d,
                main_date: moment(mainDate).format("YYYY-MM-DD HH:mm:ss"),
              };
            })
          )
        );
        setTotalItems(querySnapshot.size);

        console.log("Events Fetched From DB");
      } catch (error) {
        alert("Error in fetching");
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [isFuture, take]);

  return { events, loading, totalItems };
};
