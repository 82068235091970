import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import { useAppState } from "../../appContext";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { useFetchUtilityData } from "../../hooks/venueHooks";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const ResponsiveAppBar = (props) => {
  const navigate = useNavigate();
  const { user, logOut } = useUserAuth();
  const role = localStorage.getItem("role");
  const { pages } = props;
  const [state, dispatch] = useAppState();

  const { utilityData } = useFetchUtilityData();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const drawerWidth = 240;

  React.useEffect(() => {
    if (utilityData) {
      dispatch({
        type: "SET_MULTIPLE_PROPS",
        payload: {
          cities: utilityData.cities,
          states: utilityData.states,
        },
      });
    }
  }, [utilityData]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Webtunes
      </Typography>
      <Divider />
      <List>
        {pages.map((item) => (
          <ListItem
            key={item.title}
            onClick={() => navigate(item.route)}
            sx={{ my: 2, color: "#307fc1", display: "block" }}
            disablePadding
          >
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <AppBar
        // sx={{ background: "linear-gradient(to right, #ff6e7f, #0060eaad)" }}
        sx={{ background: "#fff", color: "#307fc1" }}
        position="fixed"
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <MusicNoteIcon
              sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            />
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Webtunes
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => {
                // Only show the "PAGES" button if the display name matches "John Elder"
                if (page.title === "Pages" && user.displayName !== "John Elder") {
                  return null;
                }
                return (
                  <Button
                    key={page.title}
                    onClick={() => navigate(page.route)}
                    sx={{ my: 2, color: "#307fc1", display: "block" }}
                  >
                    {page.title}
                  </Button>
                );
              })}
            </Box>

            <Typography
              component={"span"}
            >{`Welcome ${user.displayName}`}</Typography>
            <Button
              sx={{ marginLeft: "30px" }}
              color="inherit"
              onClick={async () => {
                await logOut();
              }}
            >
              Logout
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
};
export default ResponsiveAppBar;
