import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Pagination,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Avatar,
  CardActions,
  IconButton,
  Skeleton,
  TablePagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Attribute, AttributeLabel, AttributeValue } from "../../Attribute";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CustomAlertDialog from "../../Elements/AlertDialogue";
import { useDeleteEvent } from "../../../hooks";
import { useAppState } from "../../../appContext";
import { getAttributeValue } from "../../../utils/utility";
import { MusicNote } from "@mui/icons-material";
import FacebookTwoToneIcon from "@mui/icons-material/FacebookTwoTone";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"; 

const ITEMS_PER_PAGE = [20, 50, 100, 200, 500];

export const EventImage = ({ imgUrl, styles }) => {
  const fallBackUrl = "/images/random/defaultband.jpg";
  const [url, setUrl] = useState(imgUrl);
  const handleImageError = () => {
    setUrl(fallBackUrl);
  };
  return (
    <img
      src={url}
      alt={`event images`}
      onError={handleImageError}
      style={{ ...styles }}
    />
  );
};
const attributes = [
  {
    label: "Title",
    name: "title",
    sortable: true,
  },
  {
    label: "Description",
    name: "eventdescription",
    component: {
      type: "html",
    },
  },
  {
    label: "Event Date",
    name: "main_date",
    sortable: true,
    component: {
      type: "dateTime",
    },
  },
  {
    label: "Bands",
    name: "bands",
    state: "bands",
    component: {
      type: "array",
      contextProp: "bandname",
      queryParam: "bandId",
      path: "bands",
    },
  },
  {
    label: "Venue Name",
    name: "venue_name",
    // state: "venues",
    component: {
      type: "link",
      contextProp: "venue_name",
      queryParam: "venueId",
      identifier: "venue",
      path: "venues",
    },
  },
];
const EventAccordion = ({
  data,
  showIndicators = false,
  setPaginationProps,
  totalCount,
  loading,
  currentPage,
  setCurrentPage,
  showImage = true,
  showCompactDesign = false,
  isMainView = true,
  setRefreshTrigger = () => {},
}) => {
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const currentItems = data;

  const [itemToDelete, setItemToDelete] = useState(false);

  // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // const [currentItems, setCurrentItems] = useState([]);
  const [state, dispatch] = useAppState();
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };
  const handleChangeItemsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  useEffect(() => {
    if (setPaginationProps) {
      setPaginationProps(currentPage * itemsPerPage, itemsPerPage);
    }
  }, [currentPage, itemsPerPage]);

  const navigate = useNavigate();
  const location = useLocation();
  const { deleteEvent, loading: deleting } = useDeleteEvent();
  const handleViewDetails = useCallback((e, id) => {
    e.stopPropagation();
    const searchParams = new URLSearchParams();
    searchParams.set("eventId", id);
    navigate(`/events?${searchParams.toString()}`, {
      replace: true,
    });
    // handle view details
  }, []);

  const handleEdit = useCallback((e, id) => {
    e.stopPropagation();
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("eventId", id);
    navigate(`/editEvent?${searchParams.toString()}`);
  }, []);

  const handleDelete = useCallback((e, eventToDelete) => {
    e.stopPropagation();
    setItemToDelete(eventToDelete);
  }, []);

  const getImageUrlForBand = (bands) => {
    const bandWithImage = bands?.find(
      (band) => band.imageUrl && band.imageUrl.length > 0
    );
    // Return the img_preview if found, or undefined if no such band exists
    return bandWithImage && bandWithImage.imageUrl
      ? bandWithImage.imageUrl
      : "/images/random/defaultband.jpg";
  };

  const handleDeleteEvent = () => {
    deleteEvent(itemToDelete?.id).then(() => {
      handleCloseDialog();
    });
  };
  const handleCloseDialog = () => {
    setItemToDelete(null);
    setRefreshTrigger();
    // setPaginationProps(currentPage * itemsPerPage, itemsPerPage);
  };
  return (
    <>
      {loading ? (
        <Box width={"100%"}>
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
        </Box>
      ) : (
        <Box>
          {currentItems.map((event, index) => (
            <Accordion
              slotProps={{ transition: { unmountOnExit: true } }}
              key={+index + `${event.id + event.title}`}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ background: event?.isPastEvent && "#e2e2e2" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: { xs: "space-between", md: "normal" },
                  }}
                >
                  <Box
                    key={event.id}
                    sx={{ display: { xs: "none", md: "block" } }}
                  >
                    <EventImage
                      styles={{
                        alignSelf: "center",
                        // height: event?.imageUrl ? "20px" : "80px",
                        maxHeight: "80px",
                        marginRight: "20px",
                        width: "80px",
                      }}
                      imgUrl={
                        event?.imageUrl
                          ? event?.imageUrl
                          : event?.bands?.length
                          ? getImageUrlForBand(event?.bands)
                          : "/images/random/defaultband.jpg"
                      }
                    />
                  </Box>
                  <Box sx={{ flexGrow: { xs: 0, md: 1 } }}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      {event.title || "Event Title"}
                    </Typography>
                    {event?.bands?.length > 0 && (
                      <Typography variant="body2" sx={{ display: "block" }}>
                        <MusicNote
                          sx={{
                            height: "18px",
                            mb: "-5px",
                          }}
                        />
                        {event?.bands?.map((band) => band.bandname).join(", ")}
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      sx={{
                        display: { xs: "block", sm: "none" },
                      }}
                    >
                      <LocationOnIcon
                        sx={{ mr: 0.2, height: "16px", color: "red" }}
                      />
                      {`${event?.venue_name}, ${event?.city}, ${event?.venue_state}`}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        width: "auto",
                        background: showIndicators
                          ? new Date(event.main_date) >= new Date()
                            ? "#2d6e2d"
                            : "#9c1a1a"
                          : "transparent",
                        borderRadius: 1,
                        display: {
                          xs: "block",
                          sm: "none",
                        },
                        textAlign: {
                          color: showIndicators ? "white" : "black",
                        },
                        justifyContent: {
                          xs: "center",
                          sm: showIndicators ? "center" : "right",
                        },
                      }}
                    >
                      {moment(event.main_date).format("llll")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "right",
                      mr: {
                        xs: 0,
                        md: 5,
                      },
                    }}
                  >
                    {event?.venue_name && (
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: "right",
                          display: { xs: "none", md: "block" },
                        }}
                      >
                        <LocationOnIcon
                          sx={{ mr: 0.2, height: "16px", color: "red" }}
                        />
                        {`${event?.venue_name}, ${event?.city}, ${event?.venue_state}`}

                        {event?.fbid && (
                          <FacebookTwoToneIcon
                            sx={{
                              height: "19px",
                              marginBottom: "-2px",
                              color: "blue",
                            }}
                          />
                        )}
                      </Typography>
                    )}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontWeight: "bold", display: "inline-flex", alignItems: "center" }}
                    >
                      {event?.ticket_url && (
                        <ConfirmationNumberIcon
                          sx={{ color: "#ffa726", ml: 1 }} // Add styling as needed
                          titleAccess="Tickets Available"
                        />
                      )}
                    </Typography>
                  </Box>
                    <Box
                      sx={{
                        width: "auto",
                        background: showIndicators
                          ? new Date(event.main_date) >= new Date()
                            ? "#2d6e2d"
                            : "#9c1a1a"
                          : "transparent",
                        borderRadius: 1,
                        display: {
                          xs: "none",
                          sm: "flex",
                        },
                        justifyContent: {
                          xs: "center",
                          sm: showIndicators ? "center" : "right",
                        },
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: {
                            color: showIndicators ? "white" : "black",
                          },
                        }}
                      >
                        {moment(event.main_date).format("llll")}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      mr: {
                        xs: 0,
                        md: 5,
                      },
                      // display: {
                      //   xs: "block",
                      //   md: "flex",
                      // },
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    <IconButton
                      sx={{
                        display: { xs: "block", md: "flex" },
                      }}
                      aria-label="details"
                      onClick={(e) => handleViewDetails(e, event.id)}
                    >
                      <VisibilityIcon sx={{ color: "#2a892a" }} />
                    </IconButton>
                    <IconButton
                      sx={{
                        display: { xs: "block", md: "flex" },
                      }}
                      aria-label="edit"
                      onClick={(e) => handleEdit(e, event.id)}
                    >
                      <EditIcon sx={{ color: "#307fc1" }} />
                    </IconButton>
                    <IconButton
                      sx={{
                        display: { xs: "block", md: "flex" },
                      }}
                      aria-label="delete"
                      onClick={(e) => handleDelete(e, event)}
                    >
                      <DeleteIcon sx={{ color: "#e34e4e" }} />
                    </IconButton>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {attributes.map((attribute, idx) => (
                  <Attribute key={idx + attribute.name}>
                    <AttributeLabel>{attribute.label}</AttributeLabel>
                    <AttributeValue>
                      {attribute?.component
                        ? getAttributeValue(attribute, event, state)
                        : event[attribute.name]}
                    </AttributeValue>
                  </Attribute>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
          {totalCount > itemsPerPage && setPaginationProps && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                p: 2,
                pt: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  p: 2,
                  pt: 4,
                }}
              >
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={currentPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={itemsPerPage}
                  onRowsPerPageChange={handleChangeItemsPerPage}
                  rowsPerPageOptions={ITEMS_PER_PAGE}
                />
              </Box>
            </Box>
          )}
          <CustomAlertDialog
            title={"Delete Event Confirmation"}
            description={`Are you sure you want to delete the event ${itemToDelete?.title}`}
            proceed={handleDeleteEvent}
            open={Boolean(itemToDelete?.id)}
            isLoading={deleting}
            handleClose={handleCloseDialog}
          />
        </Box>
      )}
    </>
  );
};

export default EventAccordion;
